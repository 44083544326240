.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #fff8e6 !important; /* Warna latar belakang dengan sentuhan emas pucat */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: auto;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.filter-container label {
  font-weight: bold;
  color: #be7c1f; /* Warna emas gelap untuk teks label */
}

.date-picker {
  padding: 8px;
  border: 1px solid #c9c9c9; /* Border merah terang */
  border-radius: 5px;
  font-size: 16px;
  color: #4e4e4e; /* Teks dengan warna merah terang */
  background-color: #fff8e6; /* Latar belakang emas pucat */
}

.date-picker:hover,
.date-picker:focus {
  border-color: #be7c1f; /* Border emas gelap saat hover/fokus */
  outline: none;
  box-shadow: 0 0 5px #be7c1f; /* Glow efek emas */
}

.chart-wrapper {
  width: 100%;
}

.chart-title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  color: #96212b !important;/* Warna merah terang untuk judul */
  text-transform: uppercase; /* Membuat teks terlihat lebih tegas */
}

.bar {
  background: linear-gradient(180deg, #be7c1f 0%, #e60113 100%); /* Gradasi emas ke merah */
  border-radius: 10px;
}

.tooltip {
  color: #fff;
  background: #e60113; /* Tooltip merah terang */
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.x-axis-ticks,
.y-axis-ticks {
  color: #be7c1f; /* Warna emas gelap untuk angka di sumbu */
  font-weight: bold;
  font-size: 14px;
}
