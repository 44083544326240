/* ChartStationTJ.css */

/* Style untuk container chart */
.chart-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background: linear-gradient(135deg, #f0f8ff, #d1e7ff); /* Gradasi soft biru */
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  /* Style untuk judul chart */
  .chart-title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: #3b5998; /* Warna biru lebih cerah */
  }
  
  /* Style untuk tooltip */
  .chartjs-tooltip {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Style untuk bar */
  .chartjs-bar {
    border-radius: 10px;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shadow effect for bars */
  }
  
  /* Gradasi warna untuk bar */
  .chartjs-bar {
    background: linear-gradient(180deg, #72c2f2, #1e80c4); /* Gradasi dari biru muda ke biru tua */
  }
  
  /* Hover effect pada bar */
  .chartjs-bar:hover {
    background: linear-gradient(180deg, #1e80c4, #72c2f2) !important; /* Pembalikan warna saat hover */
    cursor: pointer;
  }
  
  /* Style untuk legend */
  .chartjs-legend {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  
  /* Style untuk axes */
  .chartjs-axis-labels {
    font-size: 14px;
    font-weight: bold;
    color: #666;
  }
  
  /* Tooltip pada chart */
  .chartjs-tooltip {
    font-size: 14px;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.9);
    color: #333;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  