/* Container for filters and buttons */
.filters-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Button group styling */
  .button-group {
    display: flex;
    gap: 10px;
  }
  .button.TJ {
    background-color: #007bff; /* Blue for TJ */
  }
  
  .button.MRT {
    background-color: #28a745; /* Green for MRT */
  }
  
  .button.LRT {
    background-color: #dc3545; /* Red for LRT */
  }
  
  /* Hover effects */
  .button:hover {
    transform: translateY(-2px);
  }
  
  .button.TJ:hover {
    background-color: #0056b3; /* Darker blue */
  }
  
  .button.MRT:hover {
    background-color: #1e7e34; /* Darker green */
  }
  
  .button.LRT:hover {
    background-color: #b21f2d; /* Darker red */
  }
  
  /* Active state for button */
  .button.inactive {
    opacity: 0.8;
  }
  
  /* Active button styling based on type */
  .button.TJ.active {
    background-color: #007bff; /* Blue for TJ */
    color: white;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.4);
  }
  
  .button.MRT.active {
    background-color: #28a745; /* Green for MRT */
    color: white;
    box-shadow: 0 4px 8px rgba(40, 167, 69, 0.4);
  }
  
  .button.LRT.active {
    background-color: #dc3545; /* Red for LRT */
    color: white;
    box-shadow: 0 4px 8px rgba(220, 53, 69, 0.4);
  }
  .button {
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease;
    background-color: #007bff;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .button.active {
    background-color: #0056b3;
  }
  
  .button.inactive {
    background-color: #f0f0f0;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .button:active {
    transform: translateY(1px);
  }
  
  /* Table styling */
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  /* Styles for table header colors based on PTO */
.table-header.TJ {
  background-color: #007bff; /* Blue for TJ */
  color: white;
}

.table-header.MRT {
  background-color: #28a745; /* Green for MRT */
  color: white;
}

.table-header.LRT {
  background-color: #dc3545; /* Red for LRT */
  color: white;
}

  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }
  
  .table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  .table tbody tr:hover {
    background-color: #eef6ff;
    transition: background-color 0.3s ease;
  }
  
  .table td {
    font-size: 14px;
  }
  
  /* Pagination */
  .pagination-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .pagination-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    margin: 0 5px;
    cursor: pointer;
    font-weight: bold;
    background-color: #007bff;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .pagination-container button:hover {
    background-color: #0056b3;
  }
  
  .pagination-container button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    box-shadow: none;
  }
  