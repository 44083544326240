.dataLRT-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.dataLRT-filtersContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #be7c1f, #e60113);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}

.dataLRT-dateFilters {
  display: flex;
  gap: 15px;
  align-items: center;
}

.dataLRT-dateFilters label {
  color: white;
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
}

.dataLRT-dateFilters input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  transition: box-shadow 0.3s ease;
}

.dataLRT-dateFilters input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(136, 33, 33, 0.5);
}

.dataLRT-buttonGroup {
  display: flex;
  gap: 10px;
}

.dataLRT-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  background: #be7c1f;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.dataLRT-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.dataLRT-buttonActive {
  background: #815516;
}

.dataLRT-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  background-color: white;
}

.dataLRT-table th {
  background: #be7c1f;
  color: white;
  padding: 14px;
  text-align: left;
  font-weight: bold;
}

.dataLRT-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.dataLRT-table tr:hover {
  background-color: #f1f1f1;
}

.dataLRT-noData {
  text-align: center;
  color: #888;
  font-style: italic;
}

.dataLRT-paginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.dataLRT-paginationContainer button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(135deg, #0056b3, #218838);
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.dataLRT-paginationContainer button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.dataLRT-loadingText {
  text-align: center;
  font-size: 18px;
  color: #333;
}

@media (max-width: 768px) {
  .dataLRT-filtersContainer {
    flex-direction: column;
    gap: 15px;
    align-items: stretch;
  }

  .dataLRT-dateFilters {
    justify-content: space-between;
  }

  .dataLRT-buttonGroup {
    justify-content: space-between;
  }

  .dataLRT-paginationContainer {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
}
