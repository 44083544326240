/* Style untuk container chart */
.chart-container {
    width: 50%;
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background: linear-gradient(135deg, #e0f7fa, #c8e6c9); /* Soft blue-green gradient */
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Style untuk judul chart */
.chart-title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: #00796b; /* Teal color for the title */
}

/* Style untuk tooltip */
.chartjs-tooltip {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Style untuk doughnut */
.chartjs-doughnut {
    border-radius: 20%;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shadow effect for doughnut */
}

/* Hover effect pada doughnut */
.chartjs-doughnut:hover {
    cursor: pointer;
}

/* Style untuk legend */
.chartjs-legend {
    font-size: 16px;
    font-weight: 600;
    color: #00796b; /* Teal color for legend */
}

/* Style untuk axes */
.chartjs-axis-labels {
    font-size: 14px;
    font-weight: bold;
    color: #666;
}

/* Tooltip pada chart */
.chartjs-tooltip {
    font-size: 14px;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.9);
    color: #00796b;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
