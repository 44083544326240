/* Container for filters and buttons */
.filters-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: linear-gradient(135deg, #28a745, #007bff);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Date Filter Styling */
.filters-container input[type="date"] {
    padding: 10px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    background-color: white;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.filters-container input[type="date"]:hover {
    background-color: #e8f5e9;
}

.filters-container input[type="date"]:focus {
    outline: none;
    border: 2px solid #28a745;
    background-color: #e8f5e9;
}

/* Button group styling */
.button-group {
    display: flex;
    gap: 10px;
}

.button {
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button:hover {
    transform: translateY(-2px);
}

.button.active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button.inactive {
    opacity: 0.8;
}

/* Green-Blue color combination */
.button.green-blue {
    background: linear-gradient(135deg, #28a745, #007bff);
}

.button.green-blue:hover {
    background: linear-gradient(135deg, #1e7e34, #0056b3);
}

.button.green-blue.active {
    background: linear-gradient(135deg, #218838, #004085);
}

/* Table styling */
.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table th {
    background: linear-gradient(135deg, #28a745, #28a745);
    color: white;
    font-weight: bold;
    padding: 12px;
}

.table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    font-size: 14px;
}

.table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.table tbody tr:hover {
    background-color: #e0f2f1;
    transition: background-color 0.3s ease;
}

/* Pagination */
.pagination-container {
    text-align: center;
    margin-top: 20px;
}

.pagination-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    margin: 0 5px;
    cursor: pointer;
    font-weight: bold;
    background: #28a745;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pagination-container button:hover {
    background: linear-gradient(135deg, #1e7e34, #0056b3);
}

.pagination-container button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    box-shadow: none;
}
