.destination-mrt-container{
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(135deg, #e3f2fd, #c8e6c9); /* Updated gradient */
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.destination-mrt__filter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.destination-mrt__filter-label {
  font-size: 16px;
  color: #00796b;
  font-weight: bold;
}

.destination-mrt__date-picker {
  align-items: start;
  padding: 8px;
  border: 1px solid #2196f3;
  border-radius: 5px;
  font-size: 16px;
  color: #004d40;
}

.destination-mrt__chart {
  width: 100%;
}

.destination-mrt__chart-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #004d40;
}
