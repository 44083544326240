.payment-method-chart-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background: linear-gradient(135deg, #e3f2fd, #c8e6c9); /* Updated gradient */
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .payment-method-chart-title {
    font-size: 20px; /* Smaller font for title */
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #00796b;
  }
  
  .payment-method-filter-container {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .payment-method-date-picker {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  
  .payment-method-chart-container canvas {
    max-height: 500px; /* Ensure chart is larger */
  }
  